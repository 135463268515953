import React from 'react';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Icon,
  Link,
  List,
  ListItem,
  PseudoBox,
  Image,
  Text
} from '@chakra-ui/core';
import {Helmet} from 'react-helmet';
import {graphql, useStaticQuery} from 'gatsby';
import {
  useAddItemToCart,
  useCart,
  useCartCount,
  useCartItems,
  useCheckoutUrl,
  useRemoveItemsFromCart,
  useRemoveItemFromCart
} from 'gatsby-theme-shopify-manager';

const Home = ({isOpen, onClose}) => {
  const cart = useCart();
  const addItemToCart = useAddItemToCart();
  const removeItemsFromCart = useRemoveItemsFromCart();
  const removeItemFromCart = useRemoveItemFromCart();
  const cartCount = useCartCount();
  const checkoutUrl = useCheckoutUrl();
  const cartItems = useCartItems();

  const btnRef = React.useRef();

  const data = useStaticQuery(
    graphql`
      query MyQuery {
        allShopifyProduct {
          nodes {
            title
            id
            variants {
              title
              id
              shopifyId
              image {
                localFile {
                  url
                }
              }
            }
            shopifyId
            images {
              originalSrc
              id
            }
            priceRange {
              maxVariantPrice {
                amount
              }
            }
          }
        }
      }
    `
  );

  function emptyCart() {
    removeItemsFromCart(cart.lineItems.map((lineItem) => lineItem.variant.id));
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Canvas Skateboards</title>
        <meta
          name="description"
          content="Canvas Limited Edition Skateboards came into existence in late 2008 when skaters and designers Arthur Sousa and David Mckoy decided to start their own skateboard/lifestyle brand with the mission use it as a creative outlet to share with the skateboard world."
        />
      </Helmet>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        size={'md'}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Your Shopping Cart</DrawerHeader>

          <DrawerBody>
            {cartItems.length < 1 ? (
              <p>Your cart is empty.</p>
            ) : (
              <>
                <List>
                  {cartItems.map((lineItem) => (
                    <ListItem my={6} key={lineItem.id}>
                      <Flex>
                        <Box
                          w={'20%'}
                          mr={4}
                          position='relative' 
                        >
                          <PseudoBox
                            _after={{
                              content: '"' + lineItem.quantity + '"',
                              width: '24px',
                              height: '24px',
                              borderRadius: "50%",
                              color: "#fff",
                              bg: "#F15F23",
                              position: "absolute",
                              fontSize: "14px",
                              top: '-10px',
                              right: '-10px',
                              textAlign: 'center'
                            }}
                          >
                            <Image src={lineItem.variant.image.src} />
                          </PseudoBox>
                        </Box>
                        <Flex
                          justifyContent="left"
                          flexDir="column"
                          flexGrow={1}
                          mx={4}
                        >
                          <Text as="h2" fontWeight="bold">
                            {lineItem.title}
                          </Text>
                          <List>
                            <ListItem fontSize=".8em">
                              Item Price: {lineItem.variant.price}
                            </ListItem>
                            <ListItem></ListItem>
                          </List>
                        </Flex>
                        <Button 
                          onClick={() => removeItemFromCart(lineItem.variant.id)}
                          _hover={{
                            bg: '#ffa3a3'
                          }} 
                        >
                          <Icon name='delete' />
                        </Button>
                      </Flex>
                    </ListItem>
                  ))}
                </List>
              </>
            )}
          </DrawerBody>

          <DrawerFooter bg="#e9e9e9">
            <Box w={'100%'}>
              {cart && (
                <Flex
                  w={'100%'}
                  justifyContent="center"
                  alignItems="center"
                  flexDir={['column', 'column', 'row', 'row']}
                >
                  <Box flexGrow={1} w={['100%', '100%', '50%', '50%']}>
                    <Text as="h2" fontSize="2xl" fontStyle="bold">
                      Total: {cart.totalPrice} CAD
                    </Text>
                    <Text as="p" fontSize="0.8rem">
                      *Taxes and shipping applied at checkout
                      <br />
                      **Griptape not included
                    </Text>
                  </Box>
                  <Box w={['100%', '100%', '50%', '50%']}>
                    <br />
                    {cartCount === 0 ? (
                      <p fontSize="0.8rem">no active checkout.</p>
                    ) : (
                      <>
                        <Link
                          w="100%"
                          paddingX="1.5rem"
                          borderRadius="0.25rem"
                          display="inline-flex"
                          height="3rem"
                          alignItems="center"
                          href={checkoutUrl}
                          justifyContent="center"
                          lineHeight="1.2"
                          bg="#F15F23"
                          color="#fff"
                          _hover={{bg: '#e47a4e'}}
                          _active={{bg: '#e47a4e'}}
                        >
                          Check Out <Icon name="arrow-forward"></Icon>
                        </Link>
                        <br />
                        <Link
                          textAlign="right"
                          fontSize="0.8rem"
                          href="#"
                          onClick={emptyCart}
                          rel="noopener noreferrer"
                        >
                          Empty Cart
                        </Link>
                      </>
                    )}
                  </Box>
                </Flex>
              )}
            </Box>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <Flex flexDirection={['column', 'column', 'row', 'row']} id="shopItems">
        {data.allShopifyProduct.nodes.map((product) => (
          <Flex
            key={product.id}
            flexDirection="column"
            position="relative"
            width="100%"
          >
            <PseudoBox
              zIndex="2"
              display="flex"
              opacity="0"
              bg="rgba(0,0,0,0.6)"
              alignItems="center"
              justifyContent="center"
              position="absolute"
              flexDirection="column"
              width="100%"
              height="100%"
              _hover={{opacity: '1'}}
            >
              <Text
                as="h1"
                fontSize={['1.65rem', '1.65rem', '2.6rem', '2.6rem']}
                color="#fff"
                textAlign="center"
                lineHeight="2rem"
                fontWeight="bold"
              >
                {product.title}
              </Text>
              <Text
                as="h3"
                fontSize={['1.2rem', '1.2rem', '2.6rem', '2.6rem']}
                fontWeight="300"
                color="#fff"
              >
                {product.priceRange.maxVariantPrice.amount}
              </Text>

              <Button
                bg="#000"
                size="lg"
                mt="24px"
                flexDir="row"
                color="#fff"
                onClick={() => addItemToCart(product.variants[0].shopifyId, 1)}
                _hover={{bg: '#F15F23'}}
              >
                Add to Cart
              </Button>
            </PseudoBox>
            <Box
              as="img"
              src={product.images[0].originalSrc}
              alt={product.title}
            />
          </Flex>
        ))}
      </Flex>
    </>
  );
};

export default Home;
